.modal1 {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content1 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  text-align: center;
}

.modal-buttons1 button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.modal-buttons1 button.logout-btn {
  background-color: #ff3b3b;
  color: #fff;
  border: none;
}

.modal-buttons1 button.cancel-btn {
  background-color: #f0f0f0;
  color: #333;
  border: none;
}

.modal-buttons1 button:hover {
  opacity: 0.8;
}

.show1 {
  display: block;
}



.backgroung-images::before {
  content: "";
  background-image: url('../images/home-bg.jpg');
  background-size: cover;
  background-position: center;
  background-position-y: 80%; 
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130vh; 
  opacity: 0.7; 
  z-index: -1; 
}

.container1 label {
  font-weight: bold;
}






.container1 {
  width: 70%;
  margin: auto;
  padding: 40px;
  border: 1px solid #a4a3a3;
  background-color: rgba(200, 193, 193, 0.5);
  border-radius: 8px;
  margin-top: 10vh;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: black;
  margin-top: 30px;
  z-index: 1; 
}

.text, .textarea, input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  color: black;
}

.price {
  display: flex;
  align-items: center;
}

.price input {
  width: 60%; 
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.price::after {
  content: "₨";
  font-size: 20px; 
  margin-left: 5px;
}


.uploadbtn {
  width: 20%;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.navbar4 {
  background-color: #333;
  overflow: hidden;  
}

.navbar4 a {
  float: left;
  display: block;
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;  
}

.navbar4 a:hover {
  background-color: #ddd;
  color: #333;
}

.dropdown3 {
  float: left;
  overflow: hidden;
}

.dropbtn3 {
  font-size: 16px;
  border: none;
  outline: none;
  color: #fff;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  padding: 14px 16px;
  
}

.dropdown-content3 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  
}

.dropdown-content3 a {
  float: none;
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content3 a:hover {
  background-color: #ddd;
}

.dropdown3:hover .dropdown-content3 {
  display: block;
}


.video-icon2{
  color: white;
}

.navbar-left4{
  float: right;
}

.progress1 {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-top: 10px;
}

.progress-bar1 {
  width: 0;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: white;
  background-color: #4caf50;
  border-radius: 5px;
}

@keyframes progress-animation {
  from { width: 0; }
} 

.progress-bar1 {
  animation: progress-animation 1s linear;
}

@media (max-width: 768px) {
  .container1 {
    width: 80%; 
    margin-left: auto;
    margin-right: auto;
  }

  .text {
    width: 90%; 
  }

  input[type="file"] {
    width: 90%; 
  }

  .uploadbtn {
    width: 100%; 
  }
}

.label-select-container {
  display: flex;
  align-items: center;
}

 .label-select-container label,
.label-select-container select {
  display: inline-block;
  margin-right: 10px; 
  
}

.status-dropdown{
  width: 30%;
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
} 




 





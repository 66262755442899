.videoRow {
    width: 80%;
    padding: 1rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:center;
    align-content: flex-start;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

}

.videoRow  .status, .video-thumbnail, .title, .videoType, .videoprice, .edit, .delete {
    width: 10%;
    text-align: left;
}
.videoprice{
    text-align: right;
}

.description {
   width: 10%;
}

.video-thumbnail img {
    border-radius: 1rem;
    width: 4rem;
    height: 3.5rem;
}
.edit , .delete{
    cursor: pointer;
    width: 3rem;
    /* font-size: 1.5rem; */
}

.updateAudio-file{
    max-width: 4rem;
}
.video-type-select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .video-type-select:hover {
    border-color: #888;
  }
  
  .video-type-select:focus {
    border-color: #555;
  }
  
  .video-type-select option {
    padding: 8px;
  }
/* .background-video-login {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }

.header{
    position: fixed;
    height: 80px;
    width: 100%;
    z-index: 100;
    padding: 0 20px;
}

a{
    text-decoration: none;
}

.nav{
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}

.nav,
.nav-item{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}
.nav_link,
.nav_logo,
.button{
    font-size: 25px;
    color: black;
}

.nav-item{
    column-gap: 50px; 
}

.nav_link{
    color: #fff;
}

.nav_link:hover {
    color: #d9d9d9;
}

.button{
padding: 6px 24px;
border: 2px solid black;
background: transparent;
border-radius: 6px;
cursor: pointer;
}

.button:active{
    transform: scale(0.98);
}

.home{
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url('../images/dashback.jpg');
    background-size: cover;
    background-position: center;
}


.form_container{
    position: absolute;
    max-width: 320px;
    width: 100%;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    background: #fff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: rgba(0,0,0,0.1);

}

.sign_up{
    display: none;
}

.form_container h2{
    font-size: 22px;
    color: #0b0217;
    text-align: center;
}

.input_box{
    position: relative;
    margin-top: 30px;
    width: 100%;
    height: 40px;
}

.input_box input{
    height: 80%;
    width: 80%;
    padding: 0 30px;
    border: none;
    outline: none;
    color: #333;
    transition: all 0.2s ease;
    border-bottom: 1.5px solid #aaaaaa;
}

.input_box input:focus{
    border-color: #7d2ae8;
}

.option_feild{
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.checkbox{
    display: flex;
    column-gap: 8px;
    white-space: nowrap;
}

.checkbox input{
    accent-color: #7d2ae8;
}

.checkbox label{
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    color: #0b0217;
}

.form_container .button{
    background: #7d2ae8;
    margin-top: 30px;
    width: 100%;
    padding: 10px 0;
    border-radius: 10px;

}


.login_signup{
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
}
  
  .lock-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 25%;
    color: black; 
    font-size: 50px; 
    margin-left: 100px;
    margin-bottom: 10px;
  }
  
  .error-message::after {
    content: "";
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .success-message {
    color: #4caf50;
    font-weight: bold;
    margin: 10px auto; 
    text-align: center; 
    width: 100%;

  }
  

.login_signup {
    font-size: 0.9rem;
    margin-top: 10px;
  }
  
  .login_signup div {
    cursor: pointer;
    color: #007BFF;
    text-decoration: underline;
    display: inline; 
    margin-left: 5px; 
  }
  
 
.forgot_pw {
    cursor: pointer;
    color: #007BFF;
    text-decoration: underline;
    font-size: 0.9rem;
    margin-top: 10px;
    margin-left: 12rem;
  }

  
   */


   /* Login.css */


  
   .header {
    color: #fff;
    text-align: center;
    /* padding: 1rem; */
    
  }
  
 
  
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url('../images/dashback.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .form_container {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    margin: auto; /* Center the form */
  }
  
  .form_container form {
    display: flex;
    flex-direction: column;
  }
  
 .form_container h2 {
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .input_box {
    margin-bottom: 1rem;
  }
  
  .form_container input {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .error-message {
    color: #ff0000;
    margin-top: 0.5rem;
  }
  
  .option_feild {
    text-align: right;
    margin-bottom: 1rem;
  }
  
  .forgot_pw {
    color: #007bff;
    cursor: pointer;
  }
  
  .lock-icon {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login_signup {
    text-align: center;
    margin-top: 1rem;
  }
  
  .login_signup div {
    color: #007bff;
    cursor: pointer;
  }
  
  .uil-times {
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
  }
  
.navbar2 {
  background-color: #333;
  overflow: hidden; 
}

.navbar2 a {
  float: left;
  display: block;
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;  
}

.navbar2 a:hover {
  background-color: #ddd;
  color: #333;
}


.dropdown1 {
  float: left;
  overflow: hidden;
}

.dropbtn1 {
  font-size: 16px;
  border: none;
  outline: none;
  color: #fff;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  padding: 14px 16px;
  
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  
}

.dropdown-content1 a {
  float: none;
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content1 a:hover {
  background-color: #ddd;
}

.dropdown1:hover .dropdown-content1 {
  display: block;
}


.video-icon1{
  color: white;
}

.navbar-left2{
  float: right;
}

.video-detail-background {
  background-color: #f0f0f0; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-nav {
  width: 100%;
  background-color: #333; 
  color: #fff; 
}

.video-detail-container {
  max-width: 800px; 
  width: 100%;
  margin: 2rem 0;
  background-color: #fff; 
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  box-sizing: border-box;
}

.video-details {
  margin-top: 1rem;
  text-align: center;
}

.react-player {
  border-radius: 10px;
}

.video-h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.video-p {
  font-size: 1rem;
  color: #666; 
}

.loading {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2rem;
}





.user-video-count-container {
  text-align: left;
  margin: 0 auto;
  padding: 10px;
  margin-top: 2rem;
}
.user-video-count-table {
  width: 70%;
  margin-top: 10px;
  border-collapse: collapse;
  margin: 0 auto;

}
.user-video-count-table th,
.user-video-count-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}
@media (max-width: 600px) {
  .user-video-count-table th, .user-video-count-table td {
    font-size: 12px; 
    padding: 6px;  
    margin: 0 auto;  
}
}
.user-video-count-table th {
  background-color: #f2f2f2;
  color:black;
}
.user-video-count-table td:first-child {
  text-align: left;
  width: 15%;
}
.user-video-count-table td:nth-child(2) {
  text-align: left;
width: 25%;
}
.user-video-count-table td:nth-child(3) {
  text-align: right;
  width: 10%;
}
.user-video-count-table td:last-child {
  text-align: right;
 width: 10%;
}

.user-video-count-table tr:hover {
  background-color: #f5f5f5;
}

.userwisearch {
  width: 19%;
  padding: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 7rem;
}
@media (max-width: 600px) {
  .userwisearch {
    width: 100%; 
    margin-right: 0; 
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}
.scroll-to-top-count {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px;
  background-color: #007bff; 
  color: #fff; 
  border-radius: 5px;
  cursor: pointer;
  margin-left: 85rem;
}
.scroll-to-top-count:hover {
  background-color: #0056b3; 
}

/* userSubscription.css */
.search-bar {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.pointer_table_option{
    cursor: pointer;
}
.pointer_table_option:hover{
    cursor: pointer;
}
.subscription-table {
    max-width: 1200px;
    margin: 40px auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.subscription-table table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

.subscription-table th, 
.subscription-table td {
    text-align: left;
    padding: 12px 15px;
}

.subscription-table th {
    background-color: #dddddd;
    color: #000000;
    font-weight: bold;
}

.subscription-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.subscription-table tr:hover {
    background-color: #f1f1f1;
}

.subscription-table td {
    border-bottom: 1px solid #dddddd;
}

.subscription-table tr:last-child td {
    border-bottom: none;
}

.subscription-table th, 
.subscription-table td {
    text-align: left;
    padding: 12px;
}

.subscription-table td {
    color: #333;
}

.subscription-table .status {
    font-weight: bold;
    color: #e76f51;
}
/* CSS for the status cells */
.status-active {
    background-color: green;
    color: white !important;
    text-align: center;
    font-weight: 900;
    letter-spacing: 0.1rem;
    padding: 5px;
    border-radius: 4px;
}

.status-expired {
    background-color: red;
    color: white !important;
    text-align: center;
    padding: 5px;
    font-weight: 900;
    letter-spacing: 0.1rem;
    border-radius: 4px;
}

/* Optional: Styling for the search bar */
.search-bar {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Optional: Styling for sortable table headers */
.pointer_table_option {
    cursor: pointer;
    text-decoration: underline;
}
.group_by_button{
    margin-bottom: 2rem;
}
.createaudio-main{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-self: unset;
}
.createaudio{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    margin-top: 3rem;
}

.show{
    color: red !important;
}
.createaudio .createaudio-allinputs{
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
}
.createaudio .createaudio-allinputs input{
    width: 100%;
}
.createaudio .createaudio-allinputs select{
    width: 100% !important;
    height: 2rem;
}
.createaudio .createaudio-allinputs .album-with-audios{
    display: flex;
    gap: 2rem;
}
.createaudio .audio-list-in-update .title-audioList{
    font-size: 1.2rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.audio-list-scrollable{
    height: 35rem;
    overflow-y: scroll;
}
.createaudio .audio-list-in-update{
    width: 30%;
    display: flex;
    flex-direction: column;
}
.createaudio .audio-list-in-update .audio-list-single-audio{
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-radius: 0.3rem;
    padding: 1rem;
    margin: 0.2rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    gap: 1rem;
}
.createaudio .audio-list-in-update .audio-list-single-audio p{
    width: 60%;
    align-self: center;
    justify-self: flex-start;
    
}
.createaudio .audio-list-in-update .audio-list-single-audio:hover{
    background-color: rgb(191, 191, 191);
}
.createaudio .audio-list-in-update .audio-list-single-audio-selected p{
    width: 60%;
    align-self: center;
    justify-self: flex-start;
    
}
.audio-list-single-audio-selected{
    background-color: rgb(219, 219, 219);
    cursor: pointer;
    border-radius: 0.3rem;
    padding: 1rem;
    margin: 0.2rem;
    box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 1px 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    gap: 1rem;
}
.createaudio .uploadAudio{
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.audio-update-buttons{
    display: flex;
    gap: 3rem;
}
.audio-list-in-update-album-label{
    font-size: 1.2rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
}
.album-list-dropdown-menu{
    height: 2rem;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
}
.audio-file-select-and-play{
    display: flex;
    align-items: center;
}
.audio-file-select-and-play label{
    display: flex;
    flex-direction: column;
}
.audio-file-select-and-play .file-selector{
    /* width: 50%; */
    border: none;
}
.audioThumbnail-in-update-audio{
    width: auto;
    height: 5rem;
    margin-left: 10px;
}
.audioThumbnail-in-audio-list{
    width: 4rem;
    height: 4rem;
    border-radius: 0.2rem;
}
.createaudio .selected{
    background-color: aliceblue;
}
.album-thubnail-preview-in-update-audio{
    width: 3rem;
    height: 3rem;
    border-radius: 1rem;
}
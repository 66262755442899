.albumrow {
    width: 80%;
    padding: 1rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:center;
    align-content: flex-start;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

}

.albumrow  .status, .album-thumbnail, .title, .albumType, .albumprice, .edit, .delete {
    width: 10%;
    text-align: left;
}
.albumprice{
    text-align: right;
}

.description {
   width: 10%;
}

.album-thumbnail img {
    border-radius: 1rem;
    width: 4rem;
    height: 3.5rem;
}
.edit , .delete{
    cursor: pointer;
    width: 3rem;
    /* font-size: 1.5rem; */
}

.updateAudio-file{
    max-width: 4rem;
}
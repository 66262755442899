.video-item{
  transform: scale(1.05); 
  transition: transform 0.3s ease; 
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
} 

.video-img1{
    width: 80%; 
    height: 150px;
    margin-left: 0%;
    border-radius: 10px;
  
} 

.video-app {
position: relative;

}
.searchh{
  padding: 15px;
  margin-right: 30px;
  margin-left: 20px;
  margin-top: 5px;
  border-radius: 20px;
  width: 300px;
  margin-top: 20px;

}

.video-item {
  margin: 0 10px;
  max-width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-right: 20px; 
  margin-top: 50px;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.video-item {
  box-sizing: border-box;
  width: 33.33%; 
  padding: 10px;
}

.video-item iframe {
  width: 100%; 
}


.searchh{
  border: none;
}
 


.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  
}


 .video {
  object-fit: cover;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
}  


.controls {
  display: flex;
  margin-bottom: 20px;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
}

.video-item {
  box-sizing: border-box;
  width: calc(33.33% - 20px);
  margin: 10px;
  text-align: center;
}

.video-item iframe {
  width: 100%;
  height: 150px;
}

.video-item p {
  margin: 10px 0;
}




 
@media (min-width: 1200px) {
  .video-item {
    width: calc(25% - 20px); 
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .video-item {
    width: calc(33.33% - 20px); 
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .video-item {
    width: calc(50% - 20px); 
  }
}

@media (max-width: 767px) {
  .video-item {
    width: calc(100% - 20px); 
  }
} 

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

 .pagination button {
  background-color: #333;
  color: #fff;
  border: none;
  margin: 0 3px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:hover {
  background-color: #333;
} 





.management-section {
  float: left;
  width: 35%;
  height: 275px;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #e0e0e0;
  background-color: #f7f1f1;
  margin-top: 10px;
  margin-left: 80px;
  border-radius: 20px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.management-section:hover {
  transform: scale(1.05); 
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.video-text h5{
  margin-bottom: 1rem;
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  color: black; 
  font-weight: bold;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

/* 
select {
  width: 30%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
} */


.management-section h2 {
  margin: 0;
}

.management-section h3 {
  margin-top: 10px;
}

 .App1 {
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  border: none;
  margin-top: 7px;
} 

.heading{
  color: white;
  font-family: italic;
  font-size: 30px;
  font-weight: bold;
}

.management-section:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.video-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px; 
  height: 70px; 
  background-color: #e5e5ec9d; 
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}


 .video-icon {
  font-size: 84px; 
}



.font-icon{
  font-size: 30px;
  color: rgb(22, 7, 96);
} 


.text-link1 {
  text-decoration: underline;
  color: black;
  font-family: Arial, sans-serif; 
  font-size: 16px; 
  font-weight: 400; 
  line-height: 1.5; 
  font-size: 14px;

}

.link:hover {
  background-color: #444;
} 


.main-container {
  background-image: url('../images/home-bg2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw; 
  height: 100vh; 
  overflow: auto; 
 background-color: rgba(247, 241, 241, 0.2);
}

.navbar1 {
  background-color: #333;
  overflow: hidden;
}

.navbar1 a {
  float: left;
  display: block;
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  
}

.navbar1 a:hover {
  background-color: #ddd;
  color: #333;
}


/* .dropdown {
  float: left;
  overflow: hidden;
} */

.dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #fff;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  padding: 14px 16px;
  
}

 .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  
}

.dropdown-content a {
  float: none;
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}


.video-icon1{
  color: white;
}

.navbar-left{
  float: right;
}


.logo-img{
  height: 30px;
  width: 40px;
  margin-top: 2px;
  margin-left: 20px;
} 






.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}



.social-links a:hover {
  transform: scale(1.2); 
  transition: transform 0.3s ease; 
}


.youtube:hover .youtube-icon,
.facebook:hover .facebook-icon,
.twitter:hover .twitter-icon {
  color: #007bb5; 
}


.social-links a {
  display: inline-block;
  margin: 0 10px;
  font-size: 30px;
  text-decoration: none;
  color: #000; 
}


.name {
  margin-right: 30px;
  font-size: 15px;
  text-align: center;
  color: white;
}

.social-links a + .name {
  margin-top: 5px;
}


.youtube-icon {
  color: #FF0000; 
}

.facebook-icon {
  color: #3b5998; 
}

.twitter-icon {
  color: #1DA1F2; 
} 




/* Responsive CSS */
@media (max-width: 768px) {
  .management-section {
    width: 80%;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .App1 {
    padding: 10px;
  }

  .navbar1 {
    padding: 10px;
  }

  .navbar1 a {
    padding: 10px;
  }

  .dropdown-content {
    min-width: 140px;
  }

  .video-circle {
    width: 50px;
    height: 50px;
  }

  .video-icon {
    font-size: 64px;
  }

  .font-icon {
    font-size: 24px;
  }

  .heading {
    font-size: 24px;
  }

  .text-link1 {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .management-section {
    width: 80%;
    margin-left: 2.5rem;
    margin-right: 0;
  }

  .navbar1 {
    text-align: center;
  }

  .navbar1 a {
    float: none;
    display: block;
  }

  .navbar-left {
    float: none;
    text-align: center;
  }

  .dropdown-content {
    position: relative;
    text-align: center;
  }
}


/* Responsive CSS for Footer */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .social-links {
    margin-top: 10px;
  }

  .social-links a {
    margin: 0 8px;
    font-size: 24px;
  }

  .name {
    margin-right: 0;
    margin-top: 10px;
  }
}

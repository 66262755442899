/* uploadalbum.css */

/* Style for the form container */
.upload-album-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 2.5rem;
  }
  
  /* Style for labels */
  .upload-album-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  /* Style for input fields */
  .upload-album-container input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Style for submit button */
  .upload-album-container button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Style for submit button on hover */
  .upload-album-container button:hover {
    background-color: #45a049;
  }
  
  /* Style for error message */
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
.subscription-btn {
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 40px;
    gap: 25rem;
}

.subscription-btn h3 {
    margin-left: 40rem;
    color: black;
}

.custom-table {
    width: 100%;
}

.custom-table-cell {
    font-weight: bold;
    border: none;
    padding: 10px;
}

.edit-icon,
.delete-icon {
    cursor: pointer;
}
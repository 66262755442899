.trancaction-details{
  margin: 20px;
  font-family: Arial, sans-serif;
}

.transaction-table {
  width: 70%;
  border-collapse: collapse; 
  margin: 0 auto;
}
.transaction-table th{
  border: 1.5px solid #ddd;
  padding: 3.5px;
  text-align: center;
}



 .transaction-table td{
  border: 1.5px solid #ddd;
  padding: 3.5px;
  text-align: left;
}

@media (max-width: 600px) {
  .transaction-table  th, .transaction-table  td {
    font-size: 12px; 
    padding: 6px;    
}
}



.transaction-table th {
  background-color: #f2f2f2;
}

.transaction-table td.Success {
  color: green;
}

.transaction-table td.Failure {
  color: red;
}
.transaction{
  text-align: center;
}

.navbart {
  background-color: #333;
  overflow: hidden;
  
}

.navbart a {
  float: left;
  display: block;
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  
}

.navbart a:hover {
  background-color: #ddd;
  color: #333;
}


.dropdownt {
  float: left;
  overflow: hidden;
}

.dropbtnt {
  font-size: 16px;
  border: none;
  outline: none;
  color: #fff;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  padding: 14px 16px;
  
}

.dropdown-contentt {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  
}

.dropdown-contentt a {
  float: none;
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-contentt a:hover {
  background-color: #ddd;
}

.dropdownt:hover .dropdown-contentt {
  display: block;
}


.video-icont{
  color: white;
}

.navbar-leftt{
  float: right;
}

.search-container {
display: flex;
align-items: center;
gap: 2rem;
width: 80%;
margin: 2rem auto;
/* margin-left: 5rem; */
}

.search-field {
display: flex;
align-items: center;
}

.search-field label {
font-weight: bold;
margin-right: 8px;
}

.search-field .react-datepicker-wrapper {
display: inline-block;
}

.search-field .react-datepicker__input-container {
display: inline-block;
}

.search-field input {
padding: 8px;
font-size: 16px;
border: 1px solid #ccc;
border-radius: 4px;
}


.search-btn {
padding: 8px 16px;
background-color: #007bff;
color: #fff;
border: none;
cursor: pointer;
border-radius: 4px;
font-size: 16px;
}

.search-btn:hover {
background-color: #0056b3;
}

.search-button {
background: linear-gradient(45deg, #3498db, #63c7ff);
color: #fff;
border: none;
border-radius: 5px;
padding: 10px 20px; 
font-size: 16px;
font-weight: bold;
cursor: pointer;
transition: background 0.3s ease, transform 0.2s ease;
text-transform: uppercase;
box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
outline: none;
}

.search-button:hover {
background: linear-gradient(45deg, #63c7ff, #3498db);
transform: scale(1.05);
}

.amount-cell {
text-align: right !important;
}
.amount{
  text-align: center !important;
}

@media (max-width: 600px) {
  .search-container {
    flex-direction: column;
    padding: 10px;
  }

  .search-field {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.transaction-id{
  text-align: center;
}
.status-dropdown{
  width: auto;
}
.albumdetails{
    width: 100%;
    margin: auto;
}
.albumdetails .album-detils {
    width: 80%;
    margin: 4rem auto;
    display: flex;
    flex-direction: row;
    gap: 2rem;

}
.album-detils .album-detils-img img{
    width: 20rem;
    border-radius: 3rem;
}
.audio-play-div{
    padding: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
.audio-play-div audio {
    width: 100%;
    height: 5rem;
    border-radius: 0px;
}
.albumdetails-information{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
}
.album-edit-edit-form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1rem;
}
.album-edit-edit-form input,textarea{
    width: 100%;
}
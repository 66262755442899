 
.custom-navbar1 {
  margin-left: auto;
  z-index: 100000;
}

.navbar-transparent-custom1 {
  position: relative;
  padding: 1rem 4rem;
  background-color: #333;
} 


@media screen and (max-width: 1100px) {
  .navbar-transparent-custom1 {
      position: sticky;
      padding: 1rem 4rem;
      top: 30px;
  }
} 

@media screen and (max-width: 965px) {
  .navbar-transparent-custom1 {
      position: sticky;
      padding: 1rem 2rem;
      top: 30px;
  }
}
@media screen and (max-width: 400px) {
  .navbar-transparent-custom1 {
      position: sticky;
      padding: 1rem 0.5rem;
      top: 30px;
  }
}

.nav-link {
  color: #fff !important; 
  margin-left: 1rem;
}

.logout-btn {
  font-size: 50px; 
  cursor: pointer;
  color: white; 
  transition: color 0.3s; 
  margin-right: 5px;
}

.logout-btn:hover {
  color: #cc0000; 
}
.logo-admin{
  color: #fff;
  font-size: 1.5rem
}
.allalbums h1{
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.allalbums-list-in-columns{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
